(function(window, document, undefined) {
    'use strict';

    HW1.layout = (function()
    {

        var MobileClass = function()
        {
            this.init()
        }
        MobileClass.prototype.events = function() {

            $('.gen__share').each(function()
            {
                var
                $ref = $(this)

                $ref.find('.button').on('click',function()
                {
                    var
                    status = $ref.hasClass('is--active')

                    if(status)
                    {
                        $ref.removeClass('is--active')
                    }
                    else
                    {
                        $ref.addClass('is--active')
                    }
                })
            })
        };
        MobileClass.prototype.init = function() {
            this.events();
        };

        // Change skin during scroll
        //==========================
        var ScrollClass = function()
        {
            this.klassDark = '.jsTracker--darkAmbiant';
            this.klassLight = '.jsTracker--lightAmbiant';
            this.init();
        }
        ScrollClass.prototype.checkScroll = function() {
            var
            self = this

            requestAnimFrame(function() {
                self.checkScroll();
            })

            var
            getScrollY = $document.scrollTop()

            if(getScrollY > 150)
            {
                $HTML.addClass('is--lightened');
            }
            else
            {
                $HTML.removeClass('is--lightened');
            }
        };
        ScrollClass.prototype.setupForLogo = function() {
            var
            self = this
            $(this.klassDark).each(function()
            {
                new Waypoint({
                    element: this,
                    handler: function(_direction) {
                        $HTML.addClass('skin--isdark')
                    },
                    offset : "15%"
                })
            })
            $(this.klassLight).each(function()
            {
                new Waypoint({
                    element: this,
                    handler: function(_direction) {
                        $HTML.removeClass('skin--isdark')
                    },
                    offset : "15%"
                })
            })
        };
        ScrollClass.prototype.events = function() {
            $document.scroll(function()
            {
                if (typeof player != 'undefined' && player.hasOwnProperty('getPlayerState'))
                {
                    var status = player.getPlayerState()
                    if( status == 1)
                    {
                        stopVideo()
                    }
                }
                else return
            })
        };
        ScrollClass.prototype.init = function() {
            this.setupForLogo();
            this.checkScroll();
            // this.events();
        };


        // Initialize the template
        //=======================
        var init = function()
        {
            new ScrollClass();
            new HW1.navigation.mainnav();
            if(isMobile) new HW1.navigation.footer();
            if(isMobile) new MobileClass();
        }

        return {
                init: init
        }
    })();

}(window, document));