var player;
function onYouTubeIframeAPIReady() {
    var
    idReceptor = 'jsElement--playerYT'
    ,$el = $(document.getElementById(idReceptor))
    ,$parent = $el.parent()

    player = new YT.Player(idReceptor, {
        height: "100%",
        width: "100%",
        videoId: $el.data('youtubeid'),
        playerVars : { autoplay: 0 , controls : 2 , theme : 'dark' , loop : 1 , modestbranding : 1, rel : 0, showinfo : 0, autohide : 1 },
        events: {
            // 'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}
function onPlayerReady(event) {
    if(!isTablet) event.target.playVideo();
}
function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING) _k.publish('player::playing');
    if (event.data == YT.PlayerState.PAUSED) _k.publish('player::stop');
    if (event.data == YT.PlayerState.ENDED) _k.publish('player::stop');
}
function stopVideo() {
    if(typeof player != 'undefined' && player.hasOwnProperty('stopVideo'))
    {
        player.stopVideo();
        _k.publish('player::stop');
    }
}