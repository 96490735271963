(function(window, document, undefined) {
    'use strict';
    /*
     * Global api.
     */
    var transition = window.transition = {
        get: function() {
            return _instance;
        },
        //Main entry point.
        init: function(options) {
            return _instance || new Transition(options);
        }
    };

    /**
     * Constructor.
     */
    function Transition(options) {
        _instance = this;
        return _instance;
    }


    // Diaporama
    //==========
    Transition.prototype.diaporama = function(_scope) {
        var
            scope               =   _scope
        ,   $ref                =   scope.$slideshow
        ,   current             =   parseInt($ref.data('index'))
        ,   next                =   scope.currentIndex
        ,   context             =   _k.getViewport()
        ,   moveX               =   (scope.items.eq(0).width() + 30) * (next -1) * -1
        ,   timeline            =   new TimelineLite({
                paused : true
            })
        ,   duration            =   .6
        ;

        if(isMobile)
        {
            moveX               =   (scope.items.eq(0).width() + 30) * (next) * -1
        }

        if(current!=next)
        {
            scope.locked            =   true;
            // timeline
            //=========
            timeline.kill();
            timeline.add(
                [
                    TweenLite.to($ref,duration,{ x : moveX , ease : Quart.easeInOut, force3D:true , onStart : function()
                    {
                        scope.update();
                    } })
                ]
            );
            timeline.call(
                function() {
                    scope.locked    =   false;
                    scope.store();

                }
            );

            timeline.restart();
        }

    }

    // Slideshow of the home
    //=======================
    Transition.prototype.slideshowGeneric = function(_scope) {
        var
            scope               =   _scope
        ,   $ref                =   scope.$slideshow
        ,   $panels             =   scope.panels
        ,   current             =   parseInt($ref.data('index'))
        ,   next                =   scope.currentIndex
        ,   context             =   _k.getViewport()
        ,   moveX               =   (scope.full)?context.width:scope.$slideshow.width()
        ,   $next               =   $ref.find('.slide').eq(next)
        ,   $current            =   $ref.find('.slide').eq(current)
        ,   timeline            =   new TimelineLite({
                paused : true
            })
        ,   duration            =   1
        ;


        if(current!=next)
        {
            scope.locked            =   true;
            // timeline
            //=========
            timeline.kill();

            var
                endCurrent      =   (current>next)?moveX:(moveX*-1)
            ,   startNext       =   (current>next)?(moveX*-1):moveX
            ;

            timeline.call(
                function() {
                    $current.css({
                        'z-index' : 2
                    });
                    $next
                        .css({
                            'z-index' : 3
                        })
                        .removeClass('disabled')
                        .addClass('enabled')
                    ;
                    scope.updateInfo();
                }
            );
            timeline.add(
                [
                    TweenLite.fromTo($current,duration ,{ scale : 1 },{ scale : .9 , ease : Expo.easeOut, force3D:true , delay : .2 }),
                    TweenLite.fromTo($next,duration,{ x : startNext },{ x : 0 , ease : Expo.easeInOut, force3D:true })
                ]
            );
            timeline.call(
                function() {
                    scope.locked    =   false;
                    TweenLite.set($current,{clearProps:"all" });
                    TweenLite.set($next,{clearProps:"all" });
                    scope.store();
                    scope.update();
                }
            );

            timeline.restart();
        }

    }

    // Singleton
    var _instance;

}(window, document));