(function(window, document, undefined) {
    'use strict';

    HW1.navigation = (function()
    {

        var MainNavClass = function()
        {
            this.view = $mainNav
            if(this.view.lengt == 0) return;
            this.init();
        }
        MainNavClass.prototype.events = function() {
            var
            self = this

            // self.view.find('.main-nav__menu').hoverIntent({
            //     over: function() { self.openSub($(this)) },
            //     out: function() { self.closeSubs() },
            //     selector: '> li',
            //     timeout : 200,
            //     interval : 100
            // });
            self.view.find('.main-nav__menu > li').on('click',function()
            {

                self.closeSubs()
                self.openSub($(this))

            })

            $master.find('.jsTrigger--mainNav').on('click',function()
            {
                self.toggle()
            })
        };
        MainNavClass.prototype.openSub = function(_el) {
            var
            $ref = _el.find('.sub-menu')
            if($ref.length == 0) return;

            var
            getHeight = $ref.find('ul').outerHeight(true)


            $ref.parent().addClass('active')
            $ref.css({
                height : getHeight
            })
        };
        MainNavClass.prototype.closeSubs = function() {
            var
            self = this

            self.view.find('.main-nav__menu > li').removeClass('active')
            self.view.find('.sub-menu').css({
                height : 0
            })
        };
        MainNavClass.prototype.toggle = function() {
            var
            self = this
            ,status = self.view.hasClass('active')

            if(status)
            {
                self.view.removeClass('active')
                $master.find('.jsTrigger--mainNav').removeClass('active');
                $HTML.removeClass('mainnav--isOpened')
                self.closeSubs()
            }
            else
            {
                self.view.addClass('active')
                $HTML.addClass('mainnav--isOpened')
                $master.find('.jsTrigger--mainNav').addClass('active');
            }
        };
        MainNavClass.prototype.init = function() {
            this.events();
        };

        // FOOTER EVENTS
        //==============
        var FooterClass = function()
        {
            this.$partners = $('.jsElement--footerPartner')
            this.init()
        }
        FooterClass.prototype.events = function()
        {
            var
            self = this

            $('.jsTrigger--footerPartner').on('click',function()
            {
                self.toggle()
            })
        }
        FooterClass.prototype.toggle = function() {
            var
            self = this
            ,status = self.$partners.hasClass('is--active')

            if(status)
            {
                self.$partners.find('.list').css({
                    height : 0
                })
                $mainContent.css({
                    top : 0,
                    paddingTop : 0
                })
                $('.jsMover--footerPartner').css({
                    top : 0
                })
                self.$partners.removeClass('is--active')
            }
            else
            {
                self.$partners.find('.list').css({
                    height : 220
                })
                $mainContent.css({
                    top : -220,
                    paddingTop : 220
                })
                $('.jsMover--footerPartner').css({
                    top : -220
                })
                self.$partners.addClass('is--active')
            }
        };
        FooterClass.prototype.init = function() {
            this.events();
        };

        return {
            mainnav:MainNavClass,
            footer:FooterClass
        }
    })();

}(window, document));

