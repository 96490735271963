(function(window, document, undefined) {
    'use strict';

    // Slideshow
    //==========
    var SlideshowClass = function(_view,_options) {
        if(typeof _view == 'undefined') return;
        this.view           =   _view;
        this.settings       =   (typeof _options != 'undefined')?_options:{};
        this.$slideshow     =   this.view.find('.jsSlideshow--collection');
        this.currentIndex   =   (typeof _options.start != 'undefined')?_options.start:0;
        this.full           =   false;
        this.locked         =   false;
        this.items          =   this.$slideshow.find('.jsSlideshow--item');
        this.$previous      =   this.view.find('.jsSlideshow--isPrevious');
        this.$next          =   this.view.find('.jsSlideshow--isNext');
        this.triggers       =   undefined;
        this.total          =   this.items.length - 1;
        this.interval       =   5000;
        this.timerAutoplay  =   false;
        this.init();
    };
    SlideshowClass.prototype = {
        events : function() {
            var
                __that       =   this
            ;

            if(__that.settings.autoplay == true)
            {
                __that.timerAutoplay = window.setInterval(function() {
                    __that.currentIndex++;
                    if(__that.currentIndex > __that.total)
                    {
                        __that.currentIndex = 0;
                    }
                    __that.goTo(__that.currentIndex,1);
                },__that.interval);
            }
            __that.$previous.on('click',function()
            {
                __that.goToPrevious()
            })
            __that.$next.on('click',function()
            {
                __that.goToNext();
            })
            __that.view.find('.jsSlideshow--buttons').on('click','li',function() {
                if(!__that.locked)
                {
                    var
                        dir
                    ;
                    if($(this).index() > __that.currentIndex)
                    {
                        dir     =   -1;
                    }
                    else
                    {
                        dir     =   1;
                    }
                    __that.currentIndex   =   $(this).index();
                    API_transition.slideshowGeneric(__that);
                    clearInterval(__that.timerAutoplay);
                }
            });
            $body.on('keydown.slideshow',function(e) {
                switch(e.which) {
                    case 37:
                        __that.$previous.trigger('click');
                        e.preventDefault();
                        return false;
                    break;
                    case 39:
                        __that.$next.trigger('click');
                        e.preventDefault();
                        return false;
                    break;
                }
            });

            if(isTablet || isMobile)
            {
                __that.swipeOn();
            }
        }
        ,
        goTo : function(_index,_dir)
        {
            if(!this.locked)
            {
                this.currentIndex   =   _index;
                API_transition.slideshowGeneric(this);
            }
        }
        ,
        goToPrevious : function() {
            if(!this.locked)
            {
                this.currentIndex--;
                if(this.currentIndex < 0)
                {
                    this.currentIndex   =   0;
                }
                API_transition.slideshowGeneric(this);
            }
        }
        ,
        goToNext : function() {
            if(!this.locked)
            {
                this.currentIndex++;
                if(this.currentIndex > this.total)
                {
                    this.currentIndex   =   this.total;
                }
                API_transition.slideshowGeneric(this);
            }
        }
        ,
        setup : function() {
            this.store();
            if(this.total == 0)
            {
                this.triggers.addClass('vs-0');
            }
            else
            {
                for(var i=0;i<=this.total;i++)
                {
                    var klassActive = (i==0)?'active':'';
                    $('<li />').html('<span></span>').addClass(klassActive).appendTo(this.view.find('.jsSlideshow--buttons'))
                }
                this.triggers = this.view.find('.jsSlideshow--buttons li')
            }
        }
        ,
        store : function() {
            this.$slideshow.data('index',this.currentIndex);
        }
        ,
        swipeOn : function() {
            var
                $ref = this.$slideshow
            ,   nameID = 'slideshowInstanceID'
            ,   __that   =   this
            ,   refID
            ;

            $ref.attr('id',nameID)
            refID = document.getElementById(nameID)

            Hammer(refID).on("swipeleft", function(event) {

                __that.$next.trigger('click');
            });
            Hammer(refID).on("swiperight", function(event) {
                __that.$previous.trigger('click');
            });
        }
        ,
        update : function() {
            this.items.removeClass('disabled enabled');
            this.items.css({
                'z-index' : 0
            }).addClass('disabled');
            this.items.eq(this.currentIndex).css({
                'z-index':1
            }).removeClass('disabled').addClass('enabled');
        }
        ,
        updateInfo : function()
        {
            this.triggers.removeClass('active');
            this.triggers.eq(this.currentIndex).addClass('active');
            if(typeof this.settings.onUpdate != 'undefined' && typeof this.settings.onUpdate == 'function') this.settings.onUpdate(this.currentIndex,this.items.eq(this.currentIndex));
        }
        ,
        init : function() {
            this.setup();
            this.events();
            this.update();
            this.updateInfo();
        }
    }

    window.SlideshowClass = SlideshowClass

}(window, document));