(function(window, document, undefined) {
    'use strict';

    HW1.blog = (function()
    {

        // Filter
        //=======
        var FilterClass = function()
        {
            this.view = $('.jsView--filter');
            this.getHeight = undefined;
            this.$items = this.view.find('.item');
            this.sticky = undefined;
            if(!this.view.length) return
            this.init();
        }
        FilterClass.prototype.events = function() {
            var
            self = this
            self.view.find('.jsTrigger--filter').on('click',function()
            {
                self.toggle();
            })
            self.view.find('.item').hover(
                function()
                {
                    self.$items.addClass('is--disabled')
                    $(this).removeClass('is--disabled');
                }
                ,
                function()
                {
                    self.$items.removeClass('is--disabled')
                }
            );

            if(!isMobile)
            {
                if($('.jsTracker--sticky').length)
                {
                    self.sticky = new Waypoint({
                        element: $('.jsTracker--sticky')[0],
                        handler: function(_direction) {
                            if(_direction == 'down')
                            {
                                self.view.addClass('is--sticky')
                                $HTML.addClass('has--sticky')
                            }
                            else
                            {
                                self.view.removeClass('is--sticky')
                                $HTML.removeClass('has--sticky')
                            }
                        }
                    });
                }
            }


        };
        FilterClass.prototype.toggle = function() {
            var
            self = this,
            status = self.view.hasClass('is--active'),
            context = _k.getViewport(),
            initHeight = (isMobile)?40:105


            if(typeof self.getHeight == "undefined") self.getHeight = self.view.find('.collection').outerHeight(true)

            if(status)
            {
                self.view.css({
                    height : initHeight
                })
                self.view.removeClass('is--active');
                $('.jsOverlay--filter').removeClass('is--active')
            }
            else
            {
                self.view.css({
                    height : self.getHeight + initHeight
                })
                self.view.addClass('is--active');
                $('.jsOverlay--filter').addClass('is--active')
            }
        };
        FilterClass.prototype.init = function() {
            this.events();
        };


        var DOM = function(_view)
        {
            this.view           =   (typeof _view != 'undefined')?_view.find('.layout__blog'):$('.layout__blog');
            this.$presentation  =   this.view.find('.jsPresentation--view')
            this.$slideshow     =   this.view.find('.jsSlideshow--instance');
            this.$grid          =   this.view.find('.js-grid')
            this.$answer        =   this.view.find('.jsReceptor--itwAnswer')
            this.init();
        }
        DOM.prototype = {
            events : function()
            {
                var
                self = this

                self.$presentation.find('.item').hover(
                    function()
                    {
                        self.$presentation.find('.item').removeClass('is--enabled is--disabled').addClass('is--disabled')
                        $(this).addClass('is--enabled')
                    }
                    ,
                    function()
                    {
                        $(this).removeClass('is--enabled is--disabled')
                        // self.$presentation.find('.item').removeClass('is--enabled is--disabled')
                    }
                );
                self.$presentation.on('mouseleave',function()
                {
                    self.$presentation.find('.item').removeClass('is--enabled is--disabled')
                })

                self.view.find('.grid-itw .jsTrigger--itwAnswer').on('click',function()
                {
                    var
                    getIndex = $(this).attr('data-answer')

                    self.view.find('.grid-itw .jsTrigger--itwAnswer').removeClass('is--active')
                    $(this).addClass('is--active');
                    self.showAnswer(getIndex)
                })

                self.$answer.find('.button').on('click',function()
                {
                    self.view.find('.grid-itw .jsTrigger--itwAnswer').removeClass('is--active')
                    self.hideAnswer()
                })
            }
            ,
            hideAnswer : function()
            {
                this.$answer.removeClass('active')
                return false;
            }
            ,
            showAnswer : function(_index)
            {
                if(this.$answer.length == 0) return;
                var
                data = itwAnswers[_index]
                ,getX = data.x*33.33
                ,getY = data.y*33.33
                ,size = data.size*33.33



                this.$answer.find('.question').html(data.title)
                this.$answer.find('.answer').html(data.description)

                if(isMobile)
                {
                    getY = this.view.find('.grid-itw .jsTrigger--itwAnswer').eq(_index).position().top
                    this.$answer.css({
                        left : 0,
                        top : getY
                    })
                }
                else
                {
                    this.$answer.css({
                        left : getX+'%',
                        top : getY+'%',
                        width : size+'%'
                    })
                }


                this.$answer.addClass('active')
            }
            ,
            init : function()
            {
                var
                self = this

                this.events();
                new FilterClass();
                //grid
                API_grid.Workspace({
                    receptor : self.$grid,
                    item : ".workspace-grid__item",
                    sizer : '.workspace-grid__sizer'
                })
                // Slideshow
                new SlideshowClass(self.$slideshow , { autoplay : false  });
                new ArticleBodyClass();

            }
        }

        var init = function()
        {
            new DOM();
        }

        var after = function(_view)
        {
            new DOM(_view);
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));