(function(window, document, undefined) {
    'use strict';

    HW1.team = (function()
    {

        // Filter
        //=======
        var FilterClass = function()
        {
            this.view = $('.jsView--filter');
            if(!this.view.length) return
            this.$triggers = this.view.find('.buttons li')
            this.$items = this.view.find('.collection .item')
            this.sticky = undefined;
            this.init();
        }
        FilterClass.prototype.events = function() {
            var
            self = this

            self.$triggers.on('click',function()
            {
                var
                getIndex = $(this).index()
                self.updateCategory(getIndex)
            })

            self.$items.find('.button').on('click',function()
            {
                self.$items.find('.button').removeClass('active')
                $(this).addClass('active')
                self.updateSubCategory();
            })

        };
        FilterClass.prototype.updateCategory = function(_index) {
            var
            self = this

            self.$triggers.removeClass('active')
            self.$items.removeClass('active')
            self.$triggers.eq(_index).addClass('active')
            self.$items.eq(_index).addClass('active')
        };
        FilterClass.prototype.updateSubCategory = function(_index) {
            console.log('do some filter');
        };
        FilterClass.prototype.init = function() {
            this.events();
            this.updateCategory(0)
        };

        // Slideshow
        //==========
        var DioparamaClass = function(_view,_options) {
            if(typeof _view == 'undefined') return;
            this.view           =   _view;
            this.settings       =   (typeof _options != 'undefined')?_options:{};
            this.$slideshow     =   this.view.find('.jsDiaporama--slider');
            this.currentIndex   =   (typeof _options.start != 'undefined')?_options.start:0;
            this.limitDown      =   (typeof _options.start != 'undefined')?_options.start:0;
            this.locked         =   false;
            this.items          =   this.$slideshow.find('.jsDiaporama--item');
            this.$previous      =   this.view.parent().find('.jsDiaporama--isPrevious');
            this.$next          =   this.view.parent().find('.jsDiaporama--isNext');
            this.total          =   this.items.length - 1;
            this.init();
        };
        DioparamaClass.prototype = {
            events : function() {
                var
                    __that       =   this
                ;
                __that.$previous.on('click',function()
                {
                    __that.goToPrevious()
                })
                __that.$next.on('click',function()
                {
                    __that.goToNext();
                })
                if(isTablet)
                {
                    __that.swipeOn();
                }
            }
            ,
            goToPrevious : function() {
                if(!this.locked)
                {
                    this.currentIndex--;
                    if(this.currentIndex < this.limitDown)
                    {
                        this.currentIndex   =   this.limitDown;
                    }
                    API_transition.diaporama(this);
                }
            }
            ,
            goToNext : function() {
                if(!this.locked)
                {
                    this.currentIndex++;
                    if(this.currentIndex > this.total)
                    {
                        this.currentIndex   =   this.total;
                    }
                    API_transition.diaporama(this);
                }
            }
            ,
            setup : function() {
                var
                context = _k.getViewport()
                if(isMobile)
                {
                    this.items.css({
                        'width' : (context.width * .8)
                    })
                    this.limitDown = 0;
                    this.currentIndex = 0
                }
                this.store();
            }
            ,
            store : function() {
                this.$slideshow.data('index',this.currentIndex);
            }
            ,
            swipeOn : function() {
                var
                    refID       =   document.getElementById('main-content')
                ,   __that   =   this
                ;
                Hammer(refID).on("swipeup", function(event) {
                    __that.$next.trigger('click');
                });
                Hammer(refID).on("swipedown", function(event) {
                    __that.$previous.trigger('click');
                });
            }
            ,
            update : function() {
                this.$previous.removeClass('disabled')
                this.$next.removeClass('disabled')
                if(this.currentIndex == this.limitDown)
                {
                    this.$previous.addClass('disabled')
                }
                if(this.currentIndex == this.total)
                {
                    this.$next.addClass('disabled');
                }
            }
            ,
            init : function() {
                this.setup();
                this.events();
                this.update();
            }
        }

        // DOM
        var DOM = function(_view)
        {
            this.view           =   (typeof _view != 'undefined')?_view.find('.layout__team'):$('.layout__team');
            this.$slideshow     =   this.view.find('.jsDiaporama--instance');
            this.init();
        }
        DOM.prototype = {
            init : function()
            {
                var
                self = this

                new FilterClass();
                new DioparamaClass(self.$slideshow , { start : 1  });

            }
        }

        var init = function()
        {
            new DOM();
        }

        var after = function(_view)
        {
            new DOM(_view);
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));