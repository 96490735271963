(function(window, document, undefined) {
    'use strict';

    function PresentationClass()
    {
        this.view = $('.jsPresentation--view');
        if(this.view.length == 0) return;
        this.init();
    }
    PresentationClass.prototype = {
        channels : function()
        {
            var
            self = this

            _k.subscribe('player::playing',function()
            {
                self.showPlayer();
            })
            _k.subscribe('player::stop',function()
            {
                self.hidePlayer();
            })
        }
        ,
        events : function()
        {
            var
            self = this

            self.view.find('.jsPresentation--playVideo').on('click',function()
            {
                if(typeof player != undefined) player.playVideo()
            })
        }
        ,
        hidePlayer : function()
        {
            var
            self = this
            self.view.removeClass('is--playing');
        }
        ,
        showPlayer : function()
        {
            var
            self = this
            self.view.addClass('is--playing');
        }
        ,
        init : function()
        {
            this.channels()
            this.events();
        }
    }

    window.PresentationClass = PresentationClass

}(window, document));
