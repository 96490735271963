(function(window, document, undefined) {
    'use strict';

    HW1.startup = (function()
    {

        // Filter
        //=======
        var FilterClass = function()
        {
            this.view = $('.jsView--filter');
            if(!this.view.length) return
            this.$triggers = this.view.find('.buttons li')
            this.$items = this.view.find('.collection .item')
            this.sticky = undefined;
            this.init();
        }
        FilterClass.prototype.events = function() {
            var
            self = this

            self.$triggers.on('click',function()
            {
                var
                getIndex = $(this).index()
                self.updateCategory(getIndex)
            })

            self.$items.find('.button').on('click',function()
            {
                self.$items.find('.button').removeClass('active')
                $(this).addClass('active')
                self.updateSubCategory();
            })

            if(!isMobile)
            {
                self.sticky = new Waypoint({
                    element: $('.jsTracker--sticky')[0],
                    handler: function(_direction) {
                        if(_direction == 'down')
                        {
                            self.view.addClass('is--sticky')
                            $HTML.addClass('has--sticky')
                        }
                        else
                        {
                            self.view.removeClass('is--sticky')
                            $HTML.removeClass('has--sticky')
                        }
                    }
                });
            }

        };
        FilterClass.prototype.updateCategory = function(_index) {
            var
            self = this

            self.$triggers.removeClass('active')
            self.$items.removeClass('active')
            self.$triggers.eq(_index).addClass('active')
            self.$items.eq(_index).addClass('active')
        };
        FilterClass.prototype.updateSubCategory = function(_index) {
            console.log('do some filter');
        };
        FilterClass.prototype.init = function() {
            this.events();
            this.updateCategory(0)
        };


        var DOM = function(_view)
        {
            this.view           =   (typeof _view != 'undefined')?_view.find('.layout__startup'):$('.layout__startup');
            this.$slideshow     =   this.view.find('.jsSlideshow--instance');
            this.init();
        }
        DOM.prototype = {
            init : function()
            {
                var
                self = this

                new FilterClass();
                new SlideshowClass(self.$slideshow , { autoplay : false  });
            }
        }

        var init = function()
        {
            new DOM();
        }

        var after = function(_view)
        {
            new DOM(_view);
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));