(function(window, document, undefined) {
    'use strict';

    function ArticleBodyClass()
    {
        this.view = $('.item-full');
        if(this.view.length == 0) return;
        this.$receptors = this.view.find('.jsReceptor--playerBody')
        this.$triggers = this.view.find('.jsTrigger--playerBody')
        this.$players = this.view.find('.jsElement--playerBody')
        this.players = []
        this.init();
    }
    ArticleBodyClass.prototype = {
        channels : function()
        {
            var
            self = this

            _k.subscribe('player::stop',function()
            {
                self.hidePlayer();
            })
        }
        ,
        events : function()
        {
            var
            self = this

            self.$triggers.on('click',function()
            {
                var
                $ref = $(this).parent().parent().find('.jsReceptor--playerBody')
                self.launchVideo($ref,$(this).parent().parent().find('.jsReceptor--playerBody').attr('data-index'));
            })
        }
        ,
        hidePlayer : function()
        {
            var
            self = this
            self.$players.removeClass('is--playing');
        }
        ,
        launchVideo : function(_ref,_index)
        {
            var
            theplayer
            ,self = this
            ,$parent = _ref.closest('.jsElement--playerBody')

            if(typeof self.players[_index] == "object")
            {
                $parent.addClass('is--playing')
                self.players[_index].playVideo()
                return;
            }

            theplayer = new YT.Player(_ref.attr('id'), {
                height: "100%",
                width: "100%",
                videoId: _ref.data('youtubeid'),
                playerVars : { autoplay: 1 , controls : 2 , theme : 'dark' , loop : 1 , modestbranding : 1, rel : 0, showinfo : 0, autohide : 1 },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
            self.players[_index] = theplayer;
            $parent.addClass('is--playing')

        }
        ,
        setup : function()
        {
            var
            self = this

            self.$receptors.each(function(i)
            {
                var
                setID = "playerBody-"+i
                ,$ref = $(this)
                ,context = _k.getViewport()

                $ref.attr({'id':setID , 'data-width' : parseInt(context.width) , 'data-height' : parseInt(context.height*.8) , 'data-index':i })
                self.players.push("")
            })

        }
        ,
        init : function()
        {
            this.channels()
            this.events();
            this.setup();

        }
    }

    window.ArticleBodyClass = ArticleBodyClass

}(window, document));
