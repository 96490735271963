(function(window, document, undefined) {
    'use strict';

    HW1.program = (function()
    {

        // TIMELINE
        //=========
        var TimelineClass = function(_view)
        {
            if(typeof _view == 'undefined') return;
            this.view = _view
            this.$receptors = this.view.find('.jsReceptor--playerTimeline')
            this.$triggers = this.view.find('.jsTimeline--triggers li')
            this.$slideshow = this.view.find('.timeline__collection')
            this.players = ["","","","",""]
            this.init();
        }
        TimelineClass.prototype.events = function() {
            var
            self = this

            self.view.find('.jsTrigger--playerTimeline').on('click',function()
            {
                var
                $ref = $(this).closest('.slide').find('.receptor')
                self.launchVideo($ref,$(this).closest('.slide').index());
            })
            self.$triggers.on('click',function(){
                var
                index = $(this).index()
                self.openSlide(index)
            })
        };
        TimelineClass.prototype.launchVideo = function(_ref,_index)
        {
            var
            theplayer
            ,self = this
            ,$parent = _ref.closest('.slide')

            if(typeof self.players[_index] == "object")
            {
                $parent.addClass('active')
                self.players[_index].playVideo()
                return;
            }

            theplayer = new YT.Player(_ref.attr('id'), {
                height: "100%",
                width: "100%",
                videoId: _ref.data('youtubeid'),
                playerVars : { autoplay: 1 , controls : 2 , theme : 'dark' , loop : 1 , modestbranding : 1, rel : 0, showinfo : 0, autohide : 1 },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
            self.players[_index] = theplayer;
            $parent.addClass('active')
        };
        TimelineClass.prototype.openSlide = function(_index) {
            var
            self = this
            ,moveX = this.view.find('.timeline__collection').width()

            self.$slideshow.addClass('disabled');
            self.$triggers.removeClass('active')
            self.$triggers.eq(_index).addClass('active')
            self.view.find('.slide').removeClass('active').each(function(i) {
                if(typeof self.players[i] == "object")
                {
                    self.players[i].stopVideo()
                }
            })

            setTimeout(function() {
                TweenLite.set(self.$slideshow.find('.nested') , { left : -(moveX * _index) })
                TweenLite.fromTo(self.$slideshow , .5 , { top : 50 } , { top : 0 , ease : Power3.easeOut })
                self.$slideshow.removeClass('disabled');
            }, 800);
        };
        TimelineClass.prototype.setup = function() {
            var
            self = this

            this.$receptors.each(function(i)
            {
                var
                setID = "timelinePlayer-"+i
                ,$ref = $(this)
                ,$context = $ref.closest('.visual-context')
                ,dimensions = { width : $context.width() , height : $context.height() }

                $ref.attr({'id':setID , 'data-width' : $context.width() , 'data-height' : $context.height() })

            })
        };
        TimelineClass.prototype.init = function() {
            this.events()
            this.setup();
        };

        // DOM
        //====
        var DOM = function(_view)
        {
            this.view           =   (typeof _view != 'undefined')?_view.find('.layout__program'):$('.layout__program');
            this.$slideshow     =   this.view.find('.jsSlideshow--instance');
            this.$timeline      =   this.view.find('.timeline');
            this.$grid          =   this.view.find('.js-grid')
            this.init();
        }
        DOM.prototype = {
            init : function()
            {
                var
                self = this

                setTimeout(function() {
                    new TimelineClass(self.$timeline)
                }, 3000);

                new PresentationClass();
                new SlideshowClass(self.$slideshow , { autoplay : false , onUpdate:function(_index,_el)
                {
                    setTimeout(function() {
                        self.$slideshow.find('.slide').removeClass('active')
                        _el.addClass('active')
                    }, 800);

                } });

                //grid
                API_grid.Workspace({
                    receptor : self.$grid,
                    item : ".workspace-grid__item",
                    sizer : '.workspace-grid__sizer'
                })
            }
        }

        var init = function()
        {
            new DOM();
        }

        var after = function(_view)
        {
            new DOM(_view);
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));